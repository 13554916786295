function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

function keywords(arr) {
	var html = '';
	return html;
}

function highlight(keywords) {
	// Highlight selected keywords
	$('[keyword]').each(function() {
		var keyword = $(this).attr('keyword');
		var found = keywords.indexOf(keyword) != -1;
		if (found) {
			$(this).addClass('badge-primary').removeClass('badge-secondary');
		}
	});

	// Add missing keywords
	keywords.forEach(function(keyword) {
		if (!$('[name="filter"][keyword="' + keyword + '"]').length) {
			$('[name="filter"]').first().parent().append('<a href="javascript:;" class="badge badge-pill ml-1 badge-primary" onclick="keywordSelect(this)" keyword="' + keyword +'">' + keyword + "</a>");
		}
	});

	
}

function showPosts(posts) {
	posts.forEach(function(item) {
		var $card = $('#' + item.id);
		if (!$card.length) {return;}
		$card.show();
	});
}

function keywordSelect(button) {
	var keywordsStr = getQueryVariable('keyword');
	var keywords = keywordsStr ? keywordsStr.split(',') : [];
	var keyword = $(button).attr('keyword');
	var hash = $(button).attr('name');
	var index = keywords.indexOf(keyword);
	if (index > -1) { // Toggle off
		keywords.splice(index,1);
	} else { // Toggle on
		keywords.push(keyword);
	}
	if (keywords.length) {
		window.location.hash = (hash ? '#'+hash : '');
		window.location.search = 'keyword='+keywords.join(',');
		return;
	}
	window.location.hash = (hash ? '#'+hash : '');
	 
	window.location.search = '';
}

$(function() {
	var keywordsStr = getQueryVariable('keyword');
	if (!keywordsStr) {return;}
	var keywords = keywordsStr.split(',');
	if (!keywords.length) {return;}
	
	// Hide all cards
	$('[role="blog"]>div').hide();

	// Filter blog posts
	var counts = {};
	var posts = config.blog.filter(function(item) {
		if (!item.keywords) {return;}
		var intersection = item.keywords.filter(function(n) {
			counts[n] = counts[n] || 0;
			counts[n] += keywords.indexOf(n) > -1 ? 1 : 0;
			return keywords.indexOf(n) > -1;
		});
		return intersection.length > 0;
	});
	showPosts(posts);
	highlight(keywords);
});